<template>
  <div class="justify-center full-height" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between">
        <div class="text-h4">Customers</div>
      </div>
      <div class="row q-mb-xl">
        <q-btn @click="$router.push({ name: 'customersNew' })">Create New</q-btn>
      </div>
      <div class="row">
        <filter-select :options="customerList" v-bind:selection.sync="selected" label="Select Customer To Edit" />
      </div>
    </div>
  </div>
</template>

<script>
import filterSelect from 'components/filterSelect'

export default {
  components: { filterSelect },
  data () {
    return {
      customers: [],
      selected: null
    }
  },
  computed: {
    customerList: function () {
      return this.customers.map(customer => {
        return { label: customer.name, value: customer.uid }
      })
    }
  },
  created () {
    this.$store.dispatch('auth/getGlobalLocations').then(res => {
      this.customers = res
    })
  },
  watch: {
    selected: function () {
      this.$router.push({ name: 'customersEdit', params: { uid: this.selected } }).catch(() => {})
    }
  }
}
</script>
